var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_vm.code == 'report' ? _c('report-list-categories') : _vm._e(), _c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt d-none d-lg-table-cell",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.number")))]), _vm.code == 'report' ? _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.카테고리")))]) : _vm._e(), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "65%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.제목")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.작성일")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.조회수")))])])]), _c('tbody', [_vm._l(_vm.notices, function (notice, index) {
    var _vm$categories$notice;
    return _c('tr', {
      key: `notice-${index}`,
      on: {
        "click": function ($event) {
          return _vm.showBoard(notice);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")])], 1), _vm.code == 'report' ? _c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(((_vm$categories$notice = _vm.categories[notice.category]) === null || _vm$categories$notice === void 0 ? void 0 : _vm$categories$notice.text) || ""))])])]) : _vm._e(), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notice.subject))])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notice.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notice.viewCount) + " ")])]);
  }), _vm._l(_vm.boards, function (board, index) {
    var _vm$categories$board$;
    return _c('tr', {
      key: `board-${index}`,
      on: {
        "click": function ($event) {
          return _vm.showBoard(board);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.summary.totalCount - _vm.skip - index))])]), _vm.code == 'report' ? _c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(((_vm$categories$board$ = _vm.categories[board.category]) === null || _vm$categories$board$ === void 0 ? void 0 : _vm$categories$board$.text) || ""))])])]) : _vm._e(), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(board.subject))])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.viewCount) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('?mode=input');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.writing")))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "outlined": "",
      "color": "grey-d6"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('loadmore');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])], 1)], 1), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideWriteButton,
      expression: "!hideWriteButton"
    }],
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`${_vm.$route.path}/write`);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.writing")))])], 1)], 1)], 1), _vm._t("pagination")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }